/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////// B) SETUP / CONFIG
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

var 	mediaType='mobile',							//	SET DEFAULT BREAKPOINT FOR CURRENT SITE (MOBILE-FIRST)
		mobileClasses = 'small medium',				//	SET DEFAULT BREAKPOINTS FOR MOBILE DECIVES (COMPARES STRINGS)

	// DEBUGGING AND FUNCTIONS
		debug = false,								//	ENABLE DEBUGGING (CONSOLE LOGS)
		ga_tracking = true,							//	ENABLE GOOGLE TRACKING
		isThisDomain = '',							//	PREPARE DOMAIN

	// DOCUMENT SETINGS
		scrollToSpeed = 700,

	//	MENU
		mmenuLicense = '1-jqmm-2833-20171006-8f27-aac7a7',// BOUGHT VERSION FOR MULTIPLE SITES 6. OCT, 2016 (MT) https://github.com/FrDH/jQuery.mmenu/archive/master.zip
		menuTopLink = 'button-totop',				//	data-functionality="button-totop"
		menuToc ={
			'class' :'.nav-toc',
			'menuWidth':0,
			'contentWidth':0,
			'arrowWith':0,
			'availableDistance':0,
			'timerValue': 0
		},
		timeout	= null,
		show_delay = 200,
		hide_delay = 250,

	//	FEMANAGER MULTISELECT FILTER
		quickFormQuery = '',
		qLocatedArr = new Array(),
		qIndustriesArr = new Array(),
		qBranchOfIndustriesArr = new Array(),

	//	GALLERY / SLIDER
		slickSliderClass = '.media-main .slides',	//	DEFINES CLASSES ON WHICH SLIDER IS TRIGGERED
		slickSliderNewsClass = '.news-list-view.blog',	//	DEFINES CLASSES ON WHICH SLIDER IS TRIGGERED
		slickSliderEventsClass = '.news-list-view.events',	//	DEFINES CLASSES ON WHICH SLIDER IS TRIGGERED
		slickGalleryClass = '.slick-gallery',
		slickEmloyeeClass = '.employee-slick',
		slickSmallEventClass = '.event-slider',
		slickVideoClass = '.slick-slider-video';

	// 	CHECK BROWSER (FOR FLIP SUPPORT AND SUCH (CSS 3D TRANSITION-SUPPORT))

	if(isie) {
		// IE 9 AND OLDER
	} else{
		var isie = false;
	}

// FOR LOADING-HEIGHT-FIX (SLIDER AND ISOTOPE)
	var is_chrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////// C ) DOCUMENT READY (INIT)
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$(document).foundation();

$(document).ready(function() {
	mediaType = getMediaType();	//	CHECK IF THE OUTPUT IS FOR MONITOR (MEDIAQUERY >= LARGE) OR FOR MOBILE-DEVICES (MEDIAQUERY <= MEDIUM)

	mobileBody();
	buildMenuTOC();
	initAdditionalFormHandler();
	calcMainMenu();
	metaNavigation();
	metaDropdown();

	if($(slickGalleryClass).length > 0) 		initSlickGallery(slickGalleryClass);		//	TOOLS: GALLERY TRIGGER
	if($(slickSliderNewsClass).length > 0) 		initSlickNews(slickSliderNewsClass);		//	TOOLS: INIT SLICKSLIDER
	if($(slickSliderClass).length > 0) 			initSlick(slickSliderClass);				//	TOOLS: INIT SLICKSLIDER
	if($(slickEmloyeeClass).length >0)			initSlickEmployee(slickEmloyeeClass);
	if($(slickSmallEventClass).length >0)		initSlickSmallEvent(slickSmallEventClass);
	if($(slickVideoClass).length >0)			initSlickVideo(slickVideoClass);

	if($("[data-fancybox]").length > 0) 		$("[data-fancybox]").fancybox();			//	TOOLS: INIT LIGHTBOX (LIGHTBOX WITHOUT GALLERIA.IO)
	if($('.media-main .slick-slider .slick-slide').length > 0) slickHeaderWidth();
	if($('.search-toggle').length > 0) searchToggle();


	if($('.teaser-content-wrapper').length > 0) initSlickTeaserNav();

	//	ON WINDOW RESIZE FUNCTIONS
	$( window ).resize(function() {
		mediaType = getMediaType();															//	CHECK IF THE OUTPUT IS FOR MONITOR (MEDIAQUERY >= LARGE) OR FOR MOBILE-DEVICES (MEDIAQUERY <= MEDIUM)
		if($(slickSliderClass).length > 0) 	initSlick(slickSliderClass);					//	TOOLS: INIT SLICKSLIDER
		calcMainMenu();

		//CALC SLICK HEADERSLIDER
		if ($('.media-main .slick-slider .slick-slide').length > 0) slickHeaderWidth();
	});

	if($('[data-functionality="' + menuTopLink + '"]').length > 0){
		initBackToTop();
	}

	if($(window).width()<415) {
		if($('#calendar-container').length<1) {
			if($(slickSliderEventsClass).length > 0) 	initSlickEvents(slickSliderEventsClass);		//	TOOLS: INIT SLICKSLIDER
		}
	}



	if($('ul.rootline.toc-list li').length<1) $('ul.rootline.toc-list').parents('.controls-main').remove();


// FEMANGER FILTER MULTISELECT Initializations

	//	INIT SEARCH-FORM IF AVAILABLE
		if($('form.filter-form').length>0){
			initFilter();
		}

	//	INIT QUICK SEARCH-FORM IF AVAILABLE
		if($('form.prefilter-form').length>0){
			initRebuildFormQuery();
		}

	//	COMMUNICATE - CONTACT PERSON //TODO Function
		if($('button.showForm').length>0){
			$('button.showForm').bind('click',function(){
				$('.profile-information-contact').toggleClass('hidden');
			});
			if($('.profile-information-contact .close-box').length>0){
				$('.profile-information-contact .close-box').bind('click',function(){
					$('.profile-information-contact').toggleClass('hidden');
				});
			}
		}

	if($('.parallax-window-header.image').length > 0) initParallaxHeader();
	if($('.detail-link-employee').length > 0) initEmployeeDetail();
	if($('.input--hoshi').length > 0) initFloating();
	if($('.sticky-right-metacontent').length > 0) initCourseRightAcc();
	if($('.lesson-list').length > 0) initLesson();
	if($('.price-table').length > 0) initPriceTable();
	if($('#courseVideo').length > 0) playCourseVideo();
	//if($('.we-love-cookies')) initCookie();
	if($('.slick-slider-video').length > 0) specialVideoFeature();
	if($('.parallax-window').length > 0) {
		initParallax();
		initAccParaCallback();
		initTextImageParallaxCallback();
	}

	if($('.fancybox-media').length > 0) initFancyVideo();

	if($('.powermail_form_72').length > 0) schulanmeldungForm();

	//if($('.single-lesson').length > 0) initLessonsCount();

	if ($('.filter-list').length > 0)	filterFunctionNavigation(); //	TRIGGER FILTER CLICK
});

$(window).resize(function() {
 metaNavigation();
});
