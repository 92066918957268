/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////// T) TRACKING SCRIPT FOR GOOGLE ANALYTICS ETC.
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


//	DECRYPT SPAM-PROTECTED EMAILS (RETURN RAW VALUES)
	function linkTo_UnCryptMailtoRaw(s) {
		return decryptString(s,-2);
	}


//	TRACKING

	// 	GOOGLE TRACKING DOWNLOADS, EXTERNAL LINKS AND MAIL/TEL
	//	DOESN'T WORK WITH GOOGLE TAG-MANAGER. THIS SOLUTION IS MADE FOR ANALYTICS.JS-VERSION
	//	REQUIRES ENCRYPTION FUNCTION OF TYPO3 TO READ LINKS CONTAINING JAVASCRIPT->MAILTO ENCRYPT....

	if(ga_tracking){
		cl('ga tracking active:' +ga_tracking);
		var filetypes = /\.(zip|exe|dmg|pdf|doc.*|xls.*|ppt.*|mp3|txt|rar|wma|mov|avi|wmv|flv|wav)$/i;
		var baseHref = '';
		if ($('base').attr('href') != undefined) baseHref = $('base').attr('href');

		$('a').on('click', function(event) {
			var el = $(this),
				track = true,
				href = (typeof(el.attr('href')) != 'undefined' ) ? el.attr('href') :"",
				hrefCompare = href.toLowerCase(),
				elEv = [];
				elEv.value=0;
				elEv.non_i=false;
				isThisDomain = href.match(document.domain.split('.').reverse()[1] + '.' + document.domain.split('.').reverse()[0]);

			if (!href.match(/^javascript:/i)) {

				if (href.match(/^mailto\:/i)) {
					elEv.category = "email";
					elEv.action = "click";
					elEv.label = href.replace(/^mailto\:/i, '');
					elEv.loc = href;
				}else if (href.match(filetypes)) {
					var extension = (/[.]/.exec(href)) ? /[^.]+$/.exec(href) : undefined;
					elEv.category = "download";
					elEv.action = "click-" + extension[0];
					elEv.label = href.replace(/ /g,"-");
					elEv.loc = baseHref + href;
				}else if (href.match(/^https?\:/i) && !isThisDomain) {
					elEv.category = "external";
					elEv.action = "click";
					elEv.label = href.replace(/^https?\:\/\//i, '');
					elEv.non_i = true;
					elEv.loc = href;
				}else if (href.match(/^tel\:/i)) {
					elEv.category = "telephone";
					elEv.action = "click";
					elEv.label = href.replace(/^tel\:/i, '');
					elEv.loc = href;
				}else{
					track = false;
				}

			}else{

				//	EXECUTE JAVASCRIPT ON THIS LINK

				if (hrefCompare.indexOf('mailto')>=0) {
					elEv.category = "email";
					elEv.action = "click";

					var processMailto,
						encryptionKey = href.replace('javascript:linkTo_UnCryptMailto(', '');

					encryptionKey = encryptionKey.replace(');', '');
					encryptionKey = encryptionKey.substr(1,encryptionKey.length-2);
					encryptionKey = linkTo_UnCryptMailtoRaw(encryptionKey);
					cl(encryptionKey);

					// i.E. mailto:info@musterland.com?subject=Webanfrage
					processMailto =	encryptionKey.replace('mailto:','');
					if(processMailto.indexOf('?')>=1) {
						processMailto = processMailto.substr(0,processMailto.indexOf('?'));
						cl(processMailto);
						cl('change');
					}else{
						cl('no change');
					}
					elEv.label = processMailto;
					elEv.loc = href;

				}else{
					track = false;
				}

			}

			if (track) {
				cl('ga tracked');
				cl("ga('send', 'event', 'email', 'click', 'mthaler@festland.ch')");
				cl("ga('send', 'event', '"+elEv.category.toLowerCase()+"', '"+elEv.action.toLowerCase()+"', '"+elEv.label+"', '"+elEv.loc+"');");
				//ga('send', 'event', elEv.category.toLowerCase(), elEv.action.toLowerCase(), elEv.label, elEv.loc);
				ga('send', 'event', elEv.category.toLowerCase(), elEv.action.toLowerCase(), elEv.label);
				if ( el.attr('target') == undefined || el.attr('target').toLowerCase() != '_blank') {
					setTimeout(function() { location.href = elEv.loc; }, 400);
					cl('target undefined');
					return false;
				}
			}else{
				cl('ga not tracked');
			}
		});
	}