/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////// L) LIBRARIES
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//	DEVICE DETECTION

	var getMediaType =function(){

		if(mobileClasses.indexOf(Foundation.MediaQuery.current)==-1){
			return 'monitor';
		}else{
			return 'mobile';
		}
	};

//	COOKIE OPTIONS

	var createCookie = function(name, value, days){
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			var expires = "; expires=" + date.toGMTString();
		} else {
			var expires = "";
		}
		document.cookie = escape(name) + "=" + escape(value) + expires + "; path=/";
	};

	var readCookie = function(name){
		var nameEQ = escape(name) + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return unescape(c.substring(nameEQ.length, c.length));
		}
		return null;
	};

	var eraseCookie = function(name){
		createCookie(name, "", -1);
	};


//	READ URL VARS (POST PARAMETERS)

	var getQueryVariable = function(variable){
		var query = window.location.search.substring(1);
		var vars = query.split("&");
		for (var i=0;i<vars.length;i++) {
			var pair = vars[i].split("=");
			if(pair[0] == variable){return pair[1];}
		}
		return(false);
	};


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////// DEVELOPMENT
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//	CONSOLE LOG (SHORTTAG AND SUPPRESSING ERROR-MESSAGES IN IE

	function cl(v){
		if(debug===true){
			if(console){
				return console.log(v);
			}
		}
	}